// useLeagues.js
import {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setUserMoney} from '../redux/userSlice';

const useLeagues = (API_URL, userId) => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const fetchLeagues = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/all-leagues`, {
        params: {userId},
      });

      // Update leagues state with the leagues array
      setLeagues(response.data.leagues);

      // Update user's coins in Redux store
      dispatch(setUserMoney(response.data.userCoins));

      setError(null);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching leagues:', err);
    } finally {
      setLoading(false);
    }
  }, [API_URL, userId, dispatch]);

  useEffect(() => {
    fetchLeagues();
  }, [fetchLeagues]);

  const handleLeagueSearch = useCallback(
    e => {
      const searchTerm = e.target.value.toLowerCase();
      if (searchTerm === '') {
        fetchLeagues();
      } else {
        const filteredLeagues = leagues.filter(league =>
          league.nameLeague.toLowerCase().includes(searchTerm)
        );
        setLeagues(filteredLeagues);
      }
    },
    [leagues, fetchLeagues]
  );

  return {leagues, loading, error, handleLeagueSearch};
};

export default useLeagues;
