import {useState, useEffect} from 'react';
import axios from 'axios';

export const useLeagueDetailsComplete = (leagueId, isLoggedIn) => {
  const [data, setData] = useState({
    leagueInfo: null,
    usersCoinsInfo: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoggedIn) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/league-details-complete/${leagueId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [leagueId, isLoggedIn]);

  return {...data, loading, error};
};
