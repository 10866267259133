import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  loading: false,
  error: false,
  userId: '',
  userName: '',
  userMail: '',
  userToken: '',
  userMoney: '',
  hasDeposit: '',
  photoProfile: '',
  userEntryPass: false,
  isAdmin: false,
  isNewUser: false,
  onboardingStep: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userName: (state, action) => {
      state.userName = action.payload;
    },
    setUserMail: (state, action) => {
      state.userMail = action.payload;
    },
    userId: (state, action) => {
      state.userId = action.payload;
    },
    userToken: (state, action) => {
      state.userToken = action.payload;
    },
    userEntryPass: (state, action) => {
      state.userEntryPass = action.payload;
    },
    setUserHasDeposit: (state, action) => {
      state.hasDeposit = action.payload;
    },
    setUserMoney: (state, action) => {
      state.userMoney = action.payload;
    },
    setUserPhoto: (state, action) => {
      state.photoProfile = action.payload;
    },
    loginStart: state => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
    },
    loginFailure: state => {
      state.loading = false;
      state.error = true;
    },
    logout: state => {
      state.currentUser = null;
      state.loading = false;
      state.error = false;
    },
    updateEurosPlayed: (state, action) => {
      state.currentUser.user.eurosplayed = action.payload;
    },
    updateOpenLeagues: (state, action) => {
      state.currentUser.openLeagues.push(action.payload);
    },
    subscription: (state, action) => {
      if (state.currentUser.subscribedUsers.includes(action.payload)) {
        state.currentUser.subscribedUsers.splice(
          state.currentUser.subscribedUsers.findIndex(
            channelId => channelId === action.payload
          ),
          1
        );
      } else {
        state.currentUser.subscribedUsers.push(action.payload);
      }
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    incrementOnboardingStep: state => {
      state.onboardingStep += 1;
    },
    resetOnboarding: state => {
      // state.isNewUser = false; // Comment this out or remove it
      state.onboardingStep = 0;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  subscription,
  updateOpenLeagues,
  updateEurosPlayed,
  userToken,
  userName,
  userEntryPass,
  setUserMoney,
  setUserPhoto,
  userId,
  setIsAdmin,
  setUserMail,
  setIsNewUser,
  setUserHasDeposit,
  incrementOnboardingStep,
  resetOnboarding,
} = userSlice.actions;

export default userSlice.reducer;
