import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  betOdd,
  betName,
  betSigne,
  betSigneSelected,
  betHandicap,
  betType,
  setBetKey,
  betMatch,
  addSelectedBet,
  initializeLeagueBets,
} from '../redux/betSlice';

import Joyride, {STATUS} from 'react-joyride';
import {useTranslation} from 'react-i18next';
import {incrementOnboardingStep, resetOnboarding} from '../redux/userSlice';
import {Button} from 'react-bootstrap';

// Estilos para cada deporte (puedes unirlos en uno solo, o mantenerlos separados).
import styles from './MatchDetailsUnified.module.css';
/**
 * Componente unificado para manejar la vista de detalles de partido en:
 * - Fútbol (soccer) -> Con Joyride
 * - Basket         -> Sin Joyride
 * - Tennis         -> Sin Joyride
 */
const MatchDetailsUnified = ({
  sportType, // 'soccer' | 'basket' | 'tennis'
  matchDetails,
  isModalOpen,
  setIsModalOpen,
  showMatchDetails,
  setShowMatchDetails,
  isMobile,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // Estado global
  const {betTeamHome, betTeamAway} = useSelector(state => state.bet);
  const {gameName} = useSelector(state => state.actualGame);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);

  // Control de tutorial (Joyride) -> Solo se usará en fútbol
  const [runTour, setRunTour] = useState(false);

  /**
   * Pasos de Joyride (solo para fútbol).
   * Si el deporte NO es 'soccer', steps = [] y no se mostrará Joyride.
   */
  const soccerSteps = [
    {
      target: '#match-details-container',
      content: t('matchDetails.onboarding.fullTimeResult'),
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      target: '#match-winner-section',
      content: t('matchDetails.onboarding.bettingOptions'),
      disableBeacon: true,
    },
    {
      target: '#first-bet-option',
      content: t('matchDetails.onboarding.clickToBet'),
      disableBeacon: true,
    },
  ];

  // Seleccionamos los pasos según el deporte (vacío excepto para soccer).
  const steps = sportType === 'soccer' ? soccerSteps : [];

  /**
   * Efecto para inicializar el tutorial SOLO si es soccer.
   */
  useEffect(() => {
    if (showMatchDetails && isNewUser && sportType === 'soccer') {
      setRunTour(true);
      setShowMatchDetails(false);
    }
  }, [showMatchDetails, isNewUser, setShowMatchDetails, sportType]);

  /**
   * Callback para Joyride (solo afecta si hay pasos).
   */
  const handleJoyrideCallback = data => {
    const {status, type} = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  // Si no hay datos de partido, no mostramos nada
  if (!matchDetails) {
    return null;
  }

  /**
   * Función genérica para despachar la apuesta y abrir el modal
   */
  const renderModalBet = (
    odds,
    name,
    handicap,
    header,
    formattedKey,
    subkey,
    betKey
  ) => {
    // Ajustamos el header a betTeamHome / betTeamAway si es '1' o '2'
    const finalHeader =
      header === '1' ? betTeamHome : header === '2' ? betTeamAway : header;

    // Inicializamos las apuestas de la liga
    dispatch(initializeLeagueBets(gameName));

    // Disparamos la información de la apuesta
    dispatch(betMatch(`${betTeamHome} - ${betTeamAway}`));
    dispatch(betOdd(odds));
    dispatch(betName(formattedKey));
    dispatch(betHandicap(handicap));
    dispatch(betSigne(name));
    dispatch(betSigneSelected(finalHeader));
    dispatch(betType(subkey));
    dispatch(setBetKey(betKey));

    // Añadimos la apuesta seleccionada
    dispatch(addSelectedBet(gameName));

    // Abrimos el modal
    setIsModalOpen(true);
  };

  /**
   * Elegimos los estilos dinámicamente, según el deporte
   */

  /**
   * Render de la "tarjeta" de apuesta
   */
  const renderBetOption = (bet, sectionName, betKey, index = null) => {
    // En fútbol, para Joyride, marcamos la primera opción de 'full_time_result'
    const idForTutorial =
      sportType === 'soccer' && index === 0 && betKey === 'full_time_result'
        ? 'first-bet-option'
        : undefined;

    return (
      <div
        key={bet.id}
        className={`${styles.matchOddsContainer}`}
        id={idForTutorial}
        onClick={() =>
          renderModalBet(
            bet.odds,
            bet.name,
            bet.handicap,
            bet.header,
            sectionName,
            sectionName,
            betKey
          )
        }
      >
        <p className={styles.odds}>{bet.odds}</p>
        {bet.name && (
          <p className={styles.betName}>
            {bet.name === '1'
              ? betTeamHome
              : bet.name === '2'
                ? betTeamAway
                : bet.name}
          </p>
        )}
        {bet.handicap && <p className={styles.handicap}>{bet.handicap}</p>}
        {bet.header && (
          <p className={styles.betHeader}>
            {bet.header === 'teamhome'
              ? betTeamHome
              : bet.header === 'teamaway'
                ? betTeamAway
                : bet.header === '1'
                  ? betTeamHome
                  : bet.header === '2'
                    ? betTeamAway
                    : bet.header}
          </p>
        )}
      </div>
    );
  };

  /**
   * Definición de las keys (mercados) para cada deporte
   */
  const soccerOddsKeys = {
    full_time_result: 'Match Winner',
    double_chance: 'Double Chance',
    goals_over_under: 'Goals Over/Under',
    both_teams_to_score: 'Both Teams to Score',
    half_time_result: 'Half Time Result',
    alternative_handicap_result: 'Alternative Handicap',
    team_totals: 'Team Totals',
    half_time_full_time: 'Half Time/Full Time',
    asian_handicap: 'Asian Handicap',
    draw_no_bet: 'Draw No Bet',
    result_total_goals: 'Result & Total Goals',
    total_goals_both_teams_to_score: 'Total goals both team to score',
    exact_total_goals: 'Exact total goals',
    goals_odd_even: 'Goals odd even',
  };

  const basketOddsKeys = {
    full_time_result: 'Match Winner',
    double_chance: 'Double Chance',
    draw_no_bet: 'Draw No Bet',
    half_time_result: 'Half Time Result',
    half_time_full_time: 'Half Time/Full Time',
    goals_over_under: 'Goals Over/Under',
    both_teams_to_score: 'Both Teams to Score',
    alternative_handicap_result: 'Alternative Handicap',
    team_totals: 'Team Totals',
    asian_handicap: 'Asian Handicap',
    goal_line: 'Goal Line',
    result_total_goals: 'Result & Total Goals',
    total_goals_both_teams_to_score: 'Total Goals Both Teams to Score',
    exact_total_goals: 'Exact Total Goals',
    goals_odd_even: 'Goals Odd/Even',
    main_props: 'Main Props',
    half_props: 'Half Props',
  };

  const tennisOddsKeys = {
    to_win_match: 'Match Winner',
    match_handicap_games: 'Match Handicap (Games)',
    total_games_2_way: 'Total Games',
    set_betting: 'Set Betting',
    first_set_winner: 'First Set Winner',
    first_set_total_games: 'First Set Total Games',
    first_set_score: 'First Set Score',
    match_result_and_total_games: 'Match Result & Total Games',
    total_sets: 'Total Sets',
    player_to_win_at_least_one_set: 'Player To Win At Least One Set',
    correct_score_in_sets: 'Correct Score In Sets',
    any_set_to_go_to_tiebreak: 'Any Set To Go To Tiebreak',
    match_to_go_to_final_set: 'Match To Go To Final Set',
    player_to_win_first_set_and_match: 'Player To Win First Set And Match',
    player_to_win_first_game: 'Player To Win First Game',
    first_set_race: 'First Set Race',
    match_total_tiebreaks: 'Match Total Tiebreaks',
  };

  /**
   * Formatear un texto tipo "main_props" -> "Main Props"
   */
  const formatBetTitle = key =>
    key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  /**
   * Render genérico de las secciones de apuestas
   */
  const renderSection = (title, bets, key) => {
    if (!bets) return null;

    // Caso basket: main_props o half_props son objetos con sub-secciones
    if (
      sportType === 'basket' &&
      (key === 'main_props' || key === 'half_props') &&
      typeof bets === 'object'
    ) {
      return Object.entries(bets).map(([subKey, subBets]) => {
        if (!subBets || subBets.length === 0) return null;
        return (
          <div key={subKey} className={styles.betGroup}>
            <h5 className={styles.betSubtypeTitle}>{formatBetTitle(subKey)}</h5>
            <div className={styles.betRow}>
              {subBets.map(bet =>
                renderBetOption(bet, formatBetTitle(subKey), subKey)
              )}
            </div>
          </div>
        );
      });
    }

    // Para soccer o tennis (y basket en keys normales), bets es un array
    if (!Array.isArray(bets) || bets.length === 0) return null;

    return (
      <div
        key={key}
        className={styles.betGroup}
        id={
          // Para fútbol, si la sección es 'full_time_result', lo marcamos
          sportType === 'soccer' && key === 'full_time_result'
            ? 'match-winner-section'
            : undefined
        }
      >
        <h5 className={styles.betSubtypeTitle}>{title}</h5>
        <div className={styles.betRow}>
          {bets.map((bet, index) => renderBetOption(bet, title, key, index))}
        </div>
      </div>
    );
  };

  /**
   * Según el deporte, elegimos:
   * - La lista de keys (oddsKeysMap)
   * - La data a iterar (tennis usa matchDetails.main_odds)
   */
  let oddsKeysMap = {};
  let dataToIterate = {};

  switch (sportType) {
    case 'soccer':
      oddsKeysMap = soccerOddsKeys;
      dataToIterate = matchDetails;
      break;
    case 'basket':
      oddsKeysMap = basketOddsKeys;
      dataToIterate = matchDetails;
      break;
    case 'tennis':
      oddsKeysMap = tennisOddsKeys;
      dataToIterate = matchDetails.main_odds || {};
      break;
    default:
      // Por si no se especifica, asumimos soccer
      oddsKeysMap = soccerOddsKeys;
      dataToIterate = matchDetails;
      break;
  }

  /**
   * Botón "Back to list"
   */
  const handleBackToList = () => {
    setShowMatchDetails(false);
  };

  return (
    <div
      className={`
        ${styles.matchDetails}
        col-12 col-md-12 row
        ${isModalOpen ? styles.blur : ''}
      `}
      style={{overflow: 'scroll', overflowX: 'hidden', maxHeight: '84vh'}}
    >
      {/* SOLO mostramos Joyride si hay steps (i.e. si es soccer) */}
      {steps.length > 0 && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          stepIndex={onboardingStep}
          callback={handleJoyrideCallback}
          hideBackButton={true} // igual que en el soccer original
          styles={{
            options: {
              primaryColor: '#007bff',
              zIndex: 1000,
            },
            tooltip: {
              fontSize: '14px',
              padding: '15px',
            },
            buttonNext: {
              backgroundColor: '#007bff',
            },
          }}
          floaterProps={{
            disableAnimation: true,
          }}
        />
      )}

      {isMobile && (
        <Button onClick={handleBackToList} className={styles.backToListButton}>
          {t('matchDetails.backToList')}
        </Button>
      )}

      <div
        className={`${styles.matchDetailsContainer}`}
        id={sportType === 'soccer' ? 'match-details-container' : undefined}
      >
        {Object.entries(oddsKeysMap).map(([key, title]) => {
          const bets = dataToIterate[key];
          return renderSection(title, bets, key);
        })}
      </div>
    </div>
  );
};

MatchDetailsUnified.propTypes = {
  sportType: PropTypes.oneOf(['soccer', 'basket', 'tennis']),
  matchDetails: PropTypes.object,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  showMatchDetails: PropTypes.bool.isRequired,
  setShowMatchDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MatchDetailsUnified;
