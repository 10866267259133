import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {
  betMatch,
  betTeamHome,
  betTeamAway,
  matchTime,
  matchId,
} from '../redux/betSlice';
import {useTranslation} from 'react-i18next';
import styles from './matchList.module.css';

const MatchList = ({
  matches,
  userLeague,
  infoDouble,
  loading,
  showDetails,
  sportId,
  isModalOpen,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleMatchClick = (betHome, betAway, matchDate, matchId2, sportId) => {
    const event = `${betHome} - ${betAway}`;
    dispatch(betMatch(event));
    dispatch(betTeamHome(betHome));
    dispatch(betTeamAway(betAway));
    dispatch(matchTime(matchDate));
    dispatch(matchId(matchId2));
    showDetails(matchId2, sportId);
  };

  if (loading) {
    return <div className={styles.loading}>{t('matchList.loading')}</div>;
  }

  const isMatchValid = (matchTime, finishDate) => {
    const finishDateMinus3Hours = finishDate - 3 * 60 * 60; // Resta 3 horas en segundos
    return finishDateMinus3Hours > matchTime;
  };

  return (
    <div className={`${styles.matchList} ${isModalOpen ? styles.blur : ''}`}>
      {matches.map((match, index) =>
        match.home.name.includes('Esports') ? null : (
          <div key={match.id} className={styles.matchCard} id="match-card">
            <div className={styles.cardBody}>
              <div
                className={styles.teamsContainer}
                id={index === 0 ? 'match-teams-container' : undefined}
              >
                <span className={styles.teamName}>{match.home.name}</span>
                <span className={styles.versus}>VS</span>
                <span className={styles.teamName}>{match.away.name}</span>
              </div>

              <div
                className={`${styles.matchDate} ${
                  !isMatchValid(
                    match.time,
                    userLeague?.finishDate || infoDouble?.finishDate
                  ) && styles.invalid
                }`}
                id={index === 0 ? 'match-date' : undefined}
              >
                {!isMatchValid(
                  match.time,
                  userLeague?.finishDate || infoDouble?.finishDate
                ) && <div>{t('matchList.matchDateAfterFinish')}</div>}
                {new Date(match.time * 1000).toLocaleString()}
              </div>

              {isMatchValid(
                match.time,
                userLeague?.finishDate || infoDouble?.finishDate
              ) && (
                <Button
                  className={styles.seeOddsButton}
                  id={index === 0 ? 'match-odds-button' : undefined}
                  onClick={() =>
                    handleMatchClick(
                      match.home.name,
                      match.away.name,
                      match.time,
                      match.id,
                      sportId
                    )
                  }
                >
                  {t('matchList.viewOdds')}
                </Button>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

MatchList.propTypes = {
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      home: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      away: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      time: PropTypes.number.isRequired,
    })
  ).isRequired,
  userLeague: PropTypes.shape({
    finishDate: PropTypes.number,
  }),
  infoDouble: PropTypes.shape({
    finishDate: PropTypes.number,
  }),
  loading: PropTypes.bool.isRequired,
  showDetails: PropTypes.func.isRequired,
  sportId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MatchList;
