import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../../context/auth.context';
import {useDispatch, useSelector} from 'react-redux';
import {IoCloseCircleOutline} from 'react-icons/io5';
import LeagueQuiz from '../../components/LeagueQuiz';
import LeagueClassification from '../../components/LeagueClassification';
import Navigation from '../../components/Navbar';
import SubNavbar from '../../components/SubNavbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useLeagueDetailsComplete} from '../../hooks/useLeagueDetailsComplete';
import {useQuizExistence} from '../../hooks/useQuizExistence';
import {formatDecimal} from '../../helpers/formatDecimal';
import {betLeagueId} from '../../redux/betSlice';
import {useTranslation} from 'react-i18next';
import Joyride, {STATUS} from 'react-joyride';
import {
  incrementOnboardingStep,
  resetOnboarding,
  setUserMoney,
} from '../../redux/userSlice';
import PropTypes from 'prop-types';
import styles from './leagueDetails.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const LeagueDetails = ({match}) => {
  const {t} = useTranslation();
  const {isLoggedIn} = useContext(AuthContext);
  const {userId, userMoney, userName} = useSelector(state => state.user);
  const [hidePlayAgain, setHidePlayAgain] = useState('hidePlayAgain');
  const [showNoMoney, setShowNoMoney] = useState(false);
  const [noMoneyMessage, setNoMoneyMessage] = useState('');
  const [showQuiz, setShowQuiz] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const totalUserCoins = useSelector(state => state.bet.coinsInLeague);

  const dispatch = useDispatch();
  const leagueId = match.params.id;
  const history = useHistory();
  const game = 'league';

  const {leagueInfo, usersCoinsInfo, loading, error} = useLeagueDetailsComplete(
    leagueId,
    isLoggedIn
  );

  console.log('usersCoinsInfo', usersCoinsInfo);

  const {gameName, gameType} = useSelector(state => state.actualGame);
  const {quizExists} = useQuizExistence(userId, leagueId, isLoggedIn);

  useEffect(() => {
    setShowQuiz(!quizExists);
  }, [quizExists]);

  useEffect(() => {
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser]);

  useEffect(() => {
    if (totalUserCoins === 0 && leagueInfo?.finishDate > Date.now() / 1000) {
      setHidePlayAgain(true);
    } else {
      setHidePlayAgain(false);
    }
  }, [totalUserCoins, leagueInfo]);

  const steps = [
    {
      target: 'body',
      content: t('leagueDetails.onboarding.welcome'),
      placement: 'center',
      disableBeacon: true,
    },
    {
      target: '.clasificationLeague',
      content: t('leagueDetails.onboarding.classification'),
    },
    {
      target:
        '.classificationTable tr.firstPlace, .classificationTable tr.secondPlace, .classificationTable tr.thirdPlace, .classificationTable tr.winner',
      content: t('leagueDetails.onboarding.winningUser'),
    },
    {
      target:
        '.classificationTable td:nth-child(2), .classificationTable td:nth-child(3)',
      content: t('leagueDetails.onboarding.userCoins'),
    },
    {
      target: '#bet-button',
      content: t('leagueDetails.onboarding.betButton'),
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  const handleSubmitForm = e => {
    e.preventDefault();
    dispatch(betLeagueId(leagueId));
    history.push(
      `/home/${gameType}/${e.target[0].value}/${gameName}/competitions`
    );
  };

  const handleBuyCoins = async () => {
    const coinsInLeagueUserCoins = {
      userId,
      userName,
      leagueId,
      leagueCoins: leagueInfo.numberCoinsInLeague,
      inscriptionPrice: leagueInfo.inscriptionPrice,
      leagueName: leagueInfo.nameLeague,
    };

    try {
      const response = await axios.post(
        `${API_URL}/buy-coins`,
        coinsInLeagueUserCoins
      );
      if (response.data.message === "You don't have enough money") {
        setHidePlayAgain(false);
        setNoMoneyMessage(response.data.message);
        setShowNoMoney(true);
      } else {
        setHidePlayAgain(false);
        dispatch(
          setUserMoney(userMoney - coinsInLeagueUserCoins.inscriptionPrice)
        );
        window.location.reload();
      }
    } catch (error) {
      console.error('Error buying coins:', error);
    }
  };

  if (loading) {
    return <div className={styles.loading}>{t('common.loading')}</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <SubNavbar
        id={leagueId}
        game={game}
        leagueName={leagueInfo?.nameLeague}
      />
      <div className={styles.container}>
        {isLoggedIn && (
          <Joyride
            steps={steps}
            run={runTour}
            continuous={true}
            showSkipButton={true}
            showProgress={true}
            stepIndex={onboardingStep}
            callback={handleJoyrideCallback}
            styles={{
              options: {
                primaryColor: '#248061',
              },
            }}
          />
        )}

        {showQuiz && leagueInfo?.finishDate < Date.now() / 1000 && (
          <LeagueQuiz leagueId={leagueId} userId userName />
        )}

        {(leagueInfo.finishDate > Date.now() / 1000 || !showQuiz) && (
          <div className={styles.content}>
            <div className={styles.leagueInfo}>
              <h2 className={styles.leagueTitle}>
                {t('leagueDetails.welcome')} {leagueInfo?.nameLeague}
              </h2>

              <div className={styles.infoCard}>
                <div className={styles.infoRow}>
                  <span className={styles.infoLabel}>
                    {t('leagueDetails.playingFor')}
                  </span>
                  <span className={styles.infoValue}>
                    {formatDecimal(leagueInfo.potToWinners)}€
                  </span>
                </div>

                <div className={styles.infoRow}>
                  <span className={styles.infoLabel}>
                    {t('leagueDetails.potDistribution')}
                  </span>
                  <span className={styles.infoValue}>
                    {t(
                      `leagueDetails.distributions.${leagueInfo.potDistribution}`
                    )}
                  </span>
                </div>

                <div className={styles.infoRow}>
                  <span className={styles.infoLabel}>
                    {t('leagueDetails.finishDate')}
                  </span>
                  <span className={styles.infoValue}>
                    {new Date(leagueInfo.finishDate * 1000).toLocaleString()}
                  </span>
                </div>
              </div>

              {leagueInfo?.finishDate < Date.now() / 1000 ? (
                <div className={styles.alert}>
                  {t('leagueDetails.leagueFinished')}
                </div>
              ) : (
                <form onSubmit={handleSubmitForm}>
                  <input hidden name="league._id" value={leagueId} />
                  <button
                    className={styles.betButton}
                    type="submit"
                    id="bet-button"
                  >
                    {t('leagueDetails.bet')}
                  </button>
                </form>
              )}
            </div>

            <LeagueClassification
              usersCoinsInfo={usersCoinsInfo}
              leagueId={leagueId}
              leagueName={leagueInfo?.nameLeague}
              potDistribution={leagueInfo.potDistribution}
              game={game}
            />
          </div>
        )}

        {/* Buy Coins Modal - Only shown when user has 0 coins */}
        {hidePlayAgain && (
          <>
            <div
              className={styles.overlay}
              onClick={() => setHidePlayAgain(false)}
            />
            <div className={styles.modal}>
              <div className={styles.modalHeader}>
                <h3 className={styles.modalTitle}>
                  {t('leagueDetails.buyCoins', {
                    coins: leagueInfo?.numberCoinsInLeague,
                  })}
                </h3>
                <button
                  className={styles.closeButton}
                  onClick={() => setHidePlayAgain(false)}
                  aria-label="Close"
                >
                  <IoCloseCircleOutline />
                </button>
              </div>
              <div className={styles.modalContent}>
                <h5>
                  {t('leagueDetails.cost', {
                    price: leagueInfo?.inscriptionPrice,
                  })}
                </h5>
                <button className={styles.buyButton} onClick={handleBuyCoins}>
                  {t('leagueDetails.clickHere')}
                </button>
              </div>
            </div>
          </>
        )}

        {/* No Money Modal */}
        {showNoMoney && (
          <>
            <div
              className={styles.overlay}
              onClick={() => setShowNoMoney(false)}
            />
            <div className={styles.modal}>
              <div className={styles.modalHeader}>
                <button
                  className={styles.closeButton}
                  onClick={() => setShowNoMoney(false)}
                  aria-label="Close"
                >
                  <IoCloseCircleOutline />
                </button>
              </div>
              <div className={styles.modalContent}>
                <h3>{noMoneyMessage}</h3>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

LeagueDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LeagueDetails;
