import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Navigation from '../components/Navbar';
import estrategia from './images/estrategia2-1.avif';
import ganarPerdiendo from './images/ganar-perdiendo.avif';
import BreadAndLanguage from '../components/BreadAndLanguage';
import equipo2 from './images/equipo2.avif';
import jugador from './images/jugador.avif';
import styles from './AboutUsPage.module.css';
import {useState} from 'react';
import axios from 'axios';

function AboutUsPage() {
  const {t} = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const userName = useSelector(state => state.user.userName);
  const userMail = useSelector(state => state.user.userMail);

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await axios.post(`${API_URL}/auth/contact`, {
        name: userName,
        email: userMail,
        message,
      });

      if (response.status === 200) {
        setStatus('success');
        setMessage('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('error');
    }
  };

  return (
    <div className={styles.aboutUsContainer}>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.content}>
        <h1 className={styles.title}>{t('aboutUs.title')}</h1>
        <p className={styles.description}>{t('aboutUs.description1')}</p>
        <p className={styles.description}>{t('aboutUs.description2')}</p>
        <div className={styles.imageGrid}>
          <div className={styles.imageItem}>
            <img
              src={estrategia}
              alt={t('aboutUs.moreStrategic')}
              className={styles.image}
            />
            <p>{t('aboutUs.moreStrategic')}</p>
          </div>
          <div className={styles.imageItem}>
            <img
              src={ganarPerdiendo}
              alt={t('aboutUs.winEvenLosing')}
              className={styles.image}
            />
            <p>{t('aboutUs.winEvenLosing')}</p>
          </div>
          <div className={styles.imageItem}>
            <img
              src={equipo2}
              alt={t('aboutUs.playInTeam')}
              className={styles.image}
            />
            <p>{t('aboutUs.playInTeam')}</p>
          </div>
          <div className={styles.imageItem}>
            <img
              src={jugador}
              alt={t('aboutUs.professionalGamblers')}
              className={styles.image}
            />
            <p>{t('aboutUs.professionalGamblers')}</p>
          </div>
        </div>

        <h4 className={styles.contactDescription}>
          {t('aboutUs.contactDescription')}
        </h4>

        <form onSubmit={handleSubmit} className={styles.contactForm}>
          <div className={styles.formGroup}>
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              placeholder={t('aboutUs.contactForm.messagePlaceholder')}
              required
              className={styles.formTextarea}
            />
          </div>

          <button
            type="submit"
            className={styles.submitButton}
            disabled={status === 'sending'}
          >
            {status === 'sending'
              ? t('aboutUs.contactForm.sending')
              : t('aboutUs.contactForm.send')}
          </button>

          {status === 'success' && (
            <p className={styles.successMessage}>
              {t('aboutUs.contactForm.successMessage')}
            </p>
          )}
          {status === 'error' && (
            <p className={styles.errorMessage}>
              {t('aboutUs.contactForm.errorMessage')}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default AboutUsPage;
