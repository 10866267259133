import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Navigation from '../../components/Navbar';
import SubNavbar from '../../components/SubNavbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useTranslation} from 'react-i18next';
import styles from './userBets.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const BetTicket = React.memo(({bet}) => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.ticket} ${styles[`ticket-${bet.status}`]}`}>
      <div className={styles.ticketHeader}>{bet.betMatch}</div>
      <div className={styles.ticketInfo}>
        <span className={styles.ticketLabel}>{t('myBets.eventDate')}: </span>
        <span className={styles.ticketValue}>
          {new Date(bet.matchTime * 1000).toLocaleString()}
        </span>
      </div>
      <div className={styles.ticketInfo}>
        <span className={`${styles.status} ${styles[`status-${bet.status}`]}`}>
          {bet.status}
        </span>
      </div>
    </div>
  );
});

const CombinationBetTicket = React.memo(({bet}) => {
  const {t} = useTranslation();

  return (
    <div className={`${styles.ticket} ${styles[`ticket-${bet.status}`]}`}>
      <div className={styles.ticketHeader}>
        <span className={styles.combinationLabel}>
          {t('myBets.combinationBet')}
        </span>
      </div>

      {bet.bets.map((singleBet, index) => (
        <div key={index} className={styles.combinationBetItem}>
          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>
              {t('myBets.match')} {index + 1}:{' '}
            </span>
            <span className={styles.ticketValue}>{singleBet.betMatch}</span>
          </div>

          <div className={styles.ticketInfo}>
            <span className={styles.ticketLabel}>
              {t('myBets.betResult')}:{' '}
            </span>
            <span className={styles.ticketValue}>
              {singleBet.result === 'pending'
                ? t('myBets.betResultPending')
                : singleBet.result}
            </span>
          </div>
        </div>
      ))}

      <div className={styles.ticketFooter}>
        <div className={styles.ticketInfo}>
          <span
            className={`${styles.status} ${styles[`status-${bet.status}`]}`}
          >
            {bet.status}
          </span>
        </div>
      </div>
    </div>
  );
});

BetTicket.propTypes = {
  bet: PropTypes.shape({
    status: PropTypes.string.isRequired,
    matchTime: PropTypes.number.isRequired,
    betMatch: PropTypes.string.isRequired,
    betName: PropTypes.string.isRequired,
    betSigne: PropTypes.string.isRequired,
    result: PropTypes.string.isRequired,
    betAmount: PropTypes.number.isRequired,
    coinsToWin: PropTypes.number.isRequired,
  }).isRequired,
};

CombinationBetTicket.propTypes = {
  bet: PropTypes.shape({
    status: PropTypes.string.isRequired,
    bets: PropTypes.arrayOf(
      PropTypes.shape({
        betMatch: PropTypes.string.isRequired,
        betType: PropTypes.string.isRequired,
        result: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

BetTicket.displayName = 'BetTicket';
CombinationBetTicket.displayName = 'CombinationBetTicket';

const UserBets = () => {
  const {t} = useTranslation();
  const {leagueId, userId: userLeagueId, userName} = useParams();
  const [mybets, setMybets] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {userId} = useSelector(state => state.user);

  const fetchBets = useCallback(async () => {
    try {
      const [myBetResponse] = await Promise.all([
        axios.post(`${API_URL}/get-mybets`, {leagueId, userId: userLeagueId}),
        axios.post(`${API_URL}/get-mybets`, {leagueId, userId}),
        axios.post(`${API_URL}/get-userleague`, {leagueId, userId}),
      ]);

      setMybets(myBetResponse.data[0]);
      setError(null);
    } catch (error) {
      setError(t('myBets.error'));
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [leagueId, userLeagueId, userId, t]);

  useEffect(() => {
    fetchBets();
  }, [userId, fetchBets]);

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <SubNavbar />
      <div className={styles.container}>
        <h1 className={styles.title}>
          {userId === userLeagueId
            ? t('userBets.yourBets', {coins: mybets.inPlayCoins})
            : t('userBets.userBets', {username: userName || 'Unknown User'})}
        </h1>

        {loading ? (
          <div className={styles.loading}>{t('common.loading')}</div>
        ) : error ? (
          <div className={styles.error}>{error}</div>
        ) : (
          <>
            <div className={styles.coinsInfo}>
              <span className={styles.coinsAmount}>
                {mybets.inPlayCoins} {t('myBets.coinsInPlay')}
              </span>
            </div>

            <div className={styles.ticketContainer}>
              {/* Regular bets */}
              {mybets.bets?.map(bet => (
                <BetTicket key={bet._id} bet={bet} />
              ))}

              {/* Combination bets */}
              {mybets.combinationBets?.map(bet => (
                <CombinationBetTicket key={bet._id} bet={bet} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserBets;
