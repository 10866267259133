import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  betMatchId: '',
  betMatch: '',
  betTeamHome: '',
  betTeamAway: '',
  betSigne: '',
  betSigneSelected: '',
  betOdd: '',
  betName: '',
  betHandicap: '',
  betLeagueId: '',
  betLeagueName: '',
  matchTime: '',
  matchId: '',
  coinsInLeague: '',
  gameMode: '',
  betType: '',
  betSport: '',
  betKey: '',
  selectedBetsByLeague: {},
  isCombinationBet: false,
  selectedBets: [],
};

export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    betMatch: (state, action) => {
      state.betMatch = action.payload;
    },
    betTeamHome: (state, action) => {
      state.betTeamHome = action.payload;
    },
    betTeamAway: (state, action) => {
      state.betTeamAway = action.payload;
    },
    betSigne: (state, action) => {
      state.betSigne = action.payload;
    },
    betType: (state, action) => {
      state.betType = action.payload;
    },
    setBetKey: (state, action) => {
      state.betKey = action.payload;
    },
    betOdd: (state, action) => {
      state.betOdd = action.payload;
    },
    betName: (state, action) => {
      state.betName = action.payload;
    },
    betHandicap: (state, action) => {
      state.betHandicap = action.payload;
    },
    betLeagueName: (state, action) => {
      state.betLeagueName = action.payload;
    },
    betSigneSelected: (state, action) => {
      state.betSigneSelected = action.payload;
    },
    betSport: (state, action) => {
      state.betSport = action.payload;
    },
    betLeagueId: (state, action) => {
      state.betLeagueId = action.payload;
    },
    matchTime: (state, action) => {
      state.matchTime = action.payload;
    },
    matchId: (state, action) => {
      state.matchId = action.payload;
    },
    setCoinsInLeague: (state, action) => {
      state.coinsInLeague = action.payload;
    },
    gameMode: (state, action) => {
      state.gameMode = action.payload;
    },
    initializeLeagueBets: (state, action) => {
      const gameName = action.payload;
      if (!gameName) return;

      if (!state.selectedBetsByLeague) {
        state.selectedBetsByLeague = {};
      }
      if (!state.selectedBetsByLeague[gameName]) {
        state.selectedBetsByLeague[gameName] = [];
      }
      state.selectedBets = state.selectedBetsByLeague[gameName];
    },
    addSelectedBet: (state, action) => {
      const gameName = action.payload;
      if (!gameName) return;

      const newBet = {
        betMatch: state.betMatch,
        betTeamHome: state.betTeamHome,
        betTeamAway: state.betTeamAway,
        betSigne: state.betSigne,
        betSigneSelected: state.betSigneSelected,
        betOdd: state.betOdd,
        betName: state.betName,
        betHandicap: state.betHandicap,
        matchTime: state.matchTime,
        matchId: state.matchId,
        betType: state.betType,
        betKey: state.betKey,
        betSport: state.betSport,
      };

      if (!state.selectedBetsByLeague[gameName]) {
        state.selectedBetsByLeague[gameName] = [];
      }

      const isMatchRepeated = state.selectedBetsByLeague[gameName].some(
        bet => bet.matchId === state.matchId
      );
      newBet.isRepeated = isMatchRepeated;

      state.selectedBetsByLeague[gameName].push(newBet);
      state.selectedBets = state.selectedBetsByLeague[gameName];
    },
    removeSelectedBet: (state, action) => {
      const {index, gameName} = action.payload;
      if (!gameName) return;

      const leagueBets = state.selectedBetsByLeague[gameName];
      if (leagueBets) {
        const removedBet = leagueBets[index];
        if (removedBet && !removedBet.isRepeated) {
          const repeatedBetIndex = leagueBets.findIndex(
            bet => bet.isRepeated && bet.matchId === removedBet.matchId
          );
          if (repeatedBetIndex !== -1) {
            leagueBets[repeatedBetIndex].isRepeated = false;
          }
        }
        state.selectedBetsByLeague[gameName] = leagueBets.filter(
          (_, i) => i !== index
        );
        state.selectedBets = state.selectedBetsByLeague[gameName];
      }
    },
    clearSelectedBets: (state, action) => {
      const gameName = action.payload;
      if (!gameName) return;

      state.selectedBetsByLeague[gameName] = [];
      state.selectedBets = [];
    },
    setIsCombinationBet: (state, action) => {
      state.isCombinationBet = action.payload;
    },
  },
});

export const {
  betMatch,
  betTeamHome,
  betTeamAway,
  betType,
  betSigne,
  betOdd,
  betName,
  betSport,
  betHandicap,
  betSigneSelected,
  betLeagueId,
  betLeagueName,
  matchTime,
  matchId,
  setCoinsInLeague,
  setBetKey,
  gameMode,
  initializeLeagueBets,
  addSelectedBet,
  removeSelectedBet,
  clearSelectedBets,
  setIsCombinationBet,
} = betSlice.actions;

export default betSlice.reducer;
