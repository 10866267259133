import {useState, useCallback, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {setGameType, setGameId, setGameName} from '../../redux/actualGameSlice';
import {setUserMoney} from '../../redux/userSlice';
import Navigation from '../../components/Navbar';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useTranslation} from 'react-i18next';
import styles from './allDoubles.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const AllDoubles = () => {
  const [doubles, setDoubles] = useState([]);
  const [accessCodes, setAccessCodes] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const {userMoney, userId} = useSelector(state => state.user);
  const {t} = useTranslation();
  const [doublesSearch, setDoublesSearch] = useState([]);

  console.log('doubles!', doubles);

  const fetchAllDoubles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/doubles`, {
        params: {userId},
      });

      // Update doubles state
      console.log('response.data', response.data);
      setDoubles(response.data.doubles);
      setDoublesSearch(response.data.doubles);

      // Update user's coins in Redux store
      if (response.data.userCoins !== undefined) {
        dispatch(setUserMoney(response.data.userCoins));
      }
    } catch (error) {
      console.error('Error fetching doubles:', error);
      setError('Error loading doubles');
    } finally {
      setLoading(false);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userId) {
      fetchAllDoubles();
    }
  }, [fetchAllDoubles, userId]);

  const handleSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({key, direction});
  };

  const getSortedDoubles = () => {
    if (!sortConfig.key) return doubles;

    return [...doubles].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'participants') {
        aValue = a.participants.length;
        bValue = b.participants.length;
      } else if (sortConfig.key === 'finishDate') {
        aValue = new Date(a.finishDate * 1000).getTime();
        bValue = new Date(b.finishDate * 1000).getTime();
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const getSortIcon = key => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const handleAccessCodeChange = useCallback((doubleId, e) => {
    setAccessCodes(prevCodes => ({
      ...prevCodes,
      [doubleId]: e.target.value,
    }));
  }, []);

  const handleEnterDouble = useCallback(double => {
    dispatch(setGameName(double.name));
    dispatch(setGameId(double._id));
    dispatch(setGameType('doubles'));
    history.push(`/home/doubles/${double._id}/${double.name}`);
  }, []);

  const handleJoinDouble = useCallback(
    async (double, accessCode = '') => {
      if (userMoney < double.inscriptionPrice) {
        setError(t('allDoubles.notEnoughMoney'));
        return;
      }

      if (double.accessCode && double.accessCode !== accessCode) {
        setError(t('allDoubles.incorrectAccessCode'));
        return;
      }

      try {
        const response = await axios.post(`${API_URL}/join-double`, {
          userId,
          doubleId: double._id,
          coinsUpdated: userMoney - double.inscriptionPrice,
          doubleName: double.name,
          eurosPlayed: double.inscriptionPrice,
        });

        if (response.data.message === 'Successfully joined the double') {
          dispatch(setUserMoney(userMoney - double.inscriptionPrice));
          dispatch(setGameName(double.name));
          dispatch(setGameId(double._id));
          dispatch(setGameType('doubles'));
          history.push(`/home/doubles/${double._id}/${double.name}`);
        }
      } catch (error) {
        setError(error.response?.data?.message || 'Error joining double');
      }
    },
    [userId, userMoney, dispatch, history, t]
  );

  const filterDoubles = useCallback(
    searchTerm => {
      if (!searchTerm) {
        setDoubles(doublesSearch);
        return;
      }

      const filteredDoubles = doublesSearch.filter(double =>
        double.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDoubles(filteredDoubles);
    },
    [doublesSearch]
  );

  const renderDesktopTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.doublesTable}>
        <thead>
          <tr>
            <th
              onClick={() => handleSort('id')}
              className={styles.sortableHeader}
            >
              ID {getSortIcon('id')}
            </th>
            <th
              onClick={() => handleSort('name')}
              className={styles.sortableHeader}
            >
              {t('doubleCard.name')} {getSortIcon('name')}
            </th>
            <th
              onClick={() => handleSort('inscriptionPrice')}
              className={styles.sortableHeader}
            >
              {t('allDoubles.inscriptionCost')}{' '}
              {getSortIcon('inscriptionPrice')}
            </th>
            <th
              onClick={() => handleSort('participants')}
              className={styles.sortableHeader}
            >
              {t('allDoubles.participants')} {getSortIcon('participants')}
            </th>
            <th
              onClick={() => handleSort('pot')}
              className={styles.sortableHeader}
            >
              {t('allDoubles.pot')} {getSortIcon('pot')}
            </th>
            <th
              onClick={() => handleSort('finishDate')}
              className={styles.sortableHeader}
            >
              {t('allDoubles.finishDate')} {getSortIcon('finishDate')}
            </th>
            <th>{t('common.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {getSortedDoubles().map((double, index) => (
            <tr key={double._id}>
              <td>{index + 1}</td>
              <td className={styles.nameCell}>
                <div className={styles.nameContainer}>
                  <img
                    className={styles.doubleImage}
                    src={double.photo}
                    alt={double.name}
                  />
                  <span className={styles.doubleName}>{double.name}</span>
                </div>
              </td>
              <td>{double.inscriptionPrice}€</td>
              <td>
                {double.participants.length}/{double.maxParticipants}
              </td>
              <td>{(double.inscriptionPrice * 2 * 0.9).toFixed(2)}€</td>
              <td>{new Date(double.finishDate * 1000).toLocaleDateString()}</td>
              <td>{renderDoubleActions(double)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderMobileTable = (double, index) => (
    <div key={double._id} className={styles.mobileCard}>
      <div className={styles.mobileCardHeader}>
        <span className={styles.mobileId}>#{index + 1}</span>
        <div className={styles.nameContainer}>
          <img
            className={styles.doubleImage}
            src={double.photo}
            alt={double.name}
          />
          <h3 className={styles.mobileName}>{double.name}</h3>
        </div>
      </div>
      <div className={styles.mobileCardBody}>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allDoubles.inscriptionCost')}
          </span>
          <span className={styles.mobileValue}>{double.inscriptionPrice}€</span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allDoubles.participants')}
          </span>
          <span className={styles.mobileValue}>
            {double.participants.length}/{double.maxParticipants}
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>{t('allDoubles.pot')}</span>
          <span className={styles.mobileValue}>
            {(double.inscriptionPrice * 2 * 0.9).toFixed(2)}€
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allDoubles.finishDate')}
          </span>
          <span className={styles.mobileValue}>
            {new Date(double.finishDate * 1000).toLocaleDateString()}
          </span>
        </div>
      </div>
      <div className={styles.mobileActions}>{renderDoubleActions(double)}</div>
    </div>
  );

  const renderDoubleActions = double => {
    if (double.finishDate < Date.now() / 1000) {
      return (
        <p className={styles.redText}>
          <b>{t('allDoubles.doubleFinished')}</b>
        </p>
      );
    }

    if (userId) {
      if (double.participants?.includes(userId)) {
        return (
          <button
            onClick={() => handleEnterDouble(double)}
            className={styles.joinDoubleButton}
          >
            {t('allDoubles.enter')}
          </button>
        );
      }

      if (double.participants.length >= double.maxParticipants) {
        return (
          <p className={styles.redText}>
            <b>{t('allDoubles.doubleFull')}</b>
          </p>
        );
      }

      if (userMoney < double.inscriptionPrice) {
        return (
          <p className={styles.redText}>
            <b>{t('allDoubles.notEnoughMoney')}</b>
          </p>
        );
      }

      if (double.accessCode) {
        const isCorrectCode = accessCodes[double._id] === double.accessCode;

        return (
          <>
            {!isCorrectCode && (
              <input
                type="text"
                className={styles.accessCodeInput}
                value={accessCodes[double._id] || ''}
                onChange={e => handleAccessCodeChange(double._id, e)}
                placeholder={t('allDoubles.accessCode')}
              />
            )}
            {isCorrectCode && (
              <button
                onClick={() =>
                  handleJoinDouble(double, accessCodes[double._id])
                }
                className={styles.joinDoubleButton}
              >
                {t('allDoubles.joinDouble')}
              </button>
            )}
          </>
        );
      }

      return (
        <button
          onClick={() => handleJoinDouble(double)}
          className={styles.joinDoubleButton}
        >
          {t('allDoubles.joinDouble')}
        </button>
      );
    }

    return (
      <p className={styles.redText}>
        {t('allDoubles.loginToJoin')}{' '}
        <Link to="/login">{t('common.login')}</Link> {t('common.or')}{' '}
        <Link to="/signup">{t('common.signup')}</Link>
      </p>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner} />
          <p>{t('common.loading')}</p>
        </div>
      );
    }

    if (!doubles || doubles.length === 0) {
      return (
        <div className={styles.noDoublesContainer}>
          <p>{t('allDoubles.noDoublesAvailable')}</p>(
          <button
            onClick={() => history.push('/home/doubles/create-double')}
            className={styles.createDoubleButton}
          >
            {t('allDoubles.createDouble')}
          </button>
          )
        </div>
      );
    }

    return (
      <>
        <div className={styles.desktopView}>{renderDesktopTable()}</div>
        <div className={styles.mobileView}>
          {getSortedDoubles().map((double, index) =>
            renderMobileTable(double, index)
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <h1 className={styles.title}>{t('allDoubles.title')}</h1>

        <div className={styles.searchAndFilters}>
          <input
            type="text"
            onChange={e => filterDoubles(e.target.value)}
            placeholder={t('allDoubles.searchLabel')}
            className={styles.searchInput}
          />
        </div>

        {error && <p className={styles.errorMessage}>{error}</p>}

        {renderContent()}
      </div>
    </>
  );
};

export default AllDoubles;
