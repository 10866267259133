import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {setUserMoney} from '../../redux/userSlice';
import Navigation from '../../components/Navbar';
import {useTranslation} from 'react-i18next';
import styles from './depositPage.module.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePaymentForm from '../../components/StripePaymentForm';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {useHistory} from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function DepositPageContent() {
  const {t} = useTranslation();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const {userId, userToken, hasDeposit} = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [wantPromotion, setWantPromotion] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(''), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleAmountChange = e => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 1000)) {
      setAmount(value);
    }
  };

  const handlePaymentIntent = async () => {
    if (Number(amount) < 10) {
      setError(t('depositPage.minDepositWarning'));
      return null;
    }

    setIsProcessing(true);
    try {
      const intentResponse = await axios.post(
        `${API_URL}/create-payment-intent`,
        {
          amount: Number(amount),
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      return intentResponse.data.clientSecret;
    } catch (error) {
      console.error('Error creating payment intent:', error);
      setError(t('depositPage.paymentIntentErrorMessage'));
      setIsProcessing(false);
      return null;
    }
  };

  const handlePaymentSuccess = async paymentIntentId => {
    try {
      const confirmResponse = await axios.post(
        `${API_URL}/confirm-payment`,
        {
          paymentIntentId,
          promotion: wantPromotion ? Math.min(Number(amount), 20) : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (confirmResponse.data.success) {
        dispatch(setUserMoney(confirmResponse.data.newCoins));
        setAmount('');
        setError(t('depositPage.successMessage'));
      } else {
        setError(t('depositPage.errorMessage'));
      }
    } catch (error) {
      console.error('Error confirming payment:', error);
      setError(t('depositPage.errorMessage'));
    }
    setIsProcessing(false);
  };

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.depositContainer}>
        <div className={styles.switchButtons}>
          <button
            className={styles.activeButton}
            onClick={() => history.push('/home/deposit')}
          >
            {t('depositPage.deposit')}
          </button>
          <button
            className={styles.inactiveButton}
            onClick={() => history.push('/home/withdraw')}
          >
            {t('withdrawalPage.withdraw')}
          </button>
        </div>
        <h2 className={styles.title}>{t('depositPage.title')}</h2>
        <div className={styles.card}>
          <div className={styles.formGroup}>
            <div className={styles.inputWrapper}>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={handleAmountChange}
                required
                className={styles.input}
                placeholder={t('depositPage.amountPlaceholder')}
                min="10"
              />
              <span className={styles.currencySymbol}>€</span>
            </div>
            <p className={styles.inputHint}>Min: 10€</p>
          </div>

          {!hasDeposit && (
            <div className={styles.promotionWrapper}>
              <label className={styles.promotionLabel}>
                <input
                  type="checkbox"
                  checked={wantPromotion}
                  onChange={e => setWantPromotion(e.target.checked)}
                  className={styles.promotionCheckbox}
                />
                {t('depositPage.wantPromotion')}
              </label>
              {wantPromotion && (
                <p className={styles.promotionInfo}>
                  {t('depositPage.promotionInfo', {
                    amount: Math.min(Number(amount), 20),
                  })}
                </p>
              )}
            </div>
          )}

          <StripePaymentForm
            amount={amount}
            onPaymentIntent={handlePaymentIntent}
            onPaymentSuccess={handlePaymentSuccess}
            isProcessing={isProcessing}
          />

          {error && (
            <div
              className={`${styles.message} ${
                error.includes('success') ? styles.success : styles.error
              }`}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function DepositPage() {
  if (!stripePromise) {
    return <div>Error: Stripe could not be loaded.</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <DepositPageContent />
    </Elements>
  );
}

export default DepositPage;
