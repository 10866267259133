import {useState, useContext, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {AuthContext} from '../../context/auth.context';
import Navigation from '../../components/Navbar';
import {useSelector, useDispatch} from 'react-redux';
import {setGameName, setGameId, setGameType} from '../../redux/actualGameSlice';
import BreadAndLanguage from '../../components/BreadAndLanguage';
import {updateOpenLeagues} from '../../redux/leagueSlice';
import {betLeagueId} from '../../redux/betSlice';
import useLeagues from '../../hooks/useLeagues';
import useJoinLeague from '../../hooks/useJoinLeague';
import {useTranslation} from 'react-i18next';
import Joyride, {STATUS} from 'react-joyride';
import {
  incrementOnboardingStep,
  resetOnboarding,
  setUserMoney,
} from '../../redux/userSlice';
import styles from './allLeagues.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const AllLeagues = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isLoggedIn, user} = useContext(AuthContext);
  const {userId} = useSelector(state => state.user);
  const isNewUser = useSelector(state => state.user.isNewUser);
  const [accessCodes, setAccessCodes] = useState({});
  const {userMoney, userName} = useSelector(state => state.user);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const history = useHistory();
  const {leagues, handleLeagueSearch} = useLeagues(API_URL, userId);

  const {handleJoinLeague} = useJoinLeague(
    API_URL,
    user,
    userMoney,
    dispatch,
    history,
    setUserMoney,
    updateOpenLeagues
  );

  useEffect(() => {
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser]);

  const handleSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({key, direction});
  };

  const getSortedLeagues = () => {
    if (!sortConfig.key) return leagues;
    console.log('TIMEs');
    return [...leagues].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'name':
          aValue = a.nameLeague.toLowerCase();
          bValue = b.nameLeague.toLowerCase();
          break;
        case 'inscriptionPrice':
          aValue = Number(a.inscriptionPrice);
          bValue = Number(b.inscriptionPrice);
          break;
        case 'participants':
          aValue = a.participants.length;
          bValue = b.participants.length;
          break;
        case 'potToWinners':
          aValue = Number(a.potToWinners);
          bValue = Number(b.potToWinners);
          break;
        case 'potDistribution':
          aValue = a.potDistribution || '1st: 100%';
          bValue = b.potDistribution || '1st: 100%';
          break;
        case 'finishDate':
          aValue = new Date(a.finishDate * 1000).getTime();
          bValue = new Date(b.finishDate * 1000).getTime();
          break;
        default:
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const getSortIcon = key => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const handleAccessCodeChange = useCallback((leagueId, e) => {
    setAccessCodes(prevCodes => ({
      ...prevCodes,
      [leagueId]: e.target.value,
    }));
  }, []);

  const renderDesktopTable = () => (
    <div className={styles.tableContainer}>
      <table className={styles.leaguesTable}>
        <thead>
          <tr>
            <th
              onClick={() => handleSort('name')}
              className={styles.sortableHeader}
            >
              {t('allLeagues.name')} {getSortIcon('name')}
            </th>
            <th
              onClick={() => handleSort('inscriptionPrice')}
              className={styles.sortableHeader}
              id="trial-league-inscription-cost"
            >
              {t('allLeagues.inscriptionCost')}{' '}
              {getSortIcon('inscriptionPrice')}
            </th>
            <th
              onClick={() => handleSort('participants')}
              className={styles.sortableHeader}
              id="trial-league-participants"
            >
              {t('allLeagues.participants')} {getSortIcon('participants')}
            </th>
            <th
              onClick={() => handleSort('potToWinners')}
              className={styles.sortableHeader}
              id="trial-league-award"
            >
              {t('allLeagues.pot')} {getSortIcon('potToWinners')}
            </th>
            <th
              onClick={() => handleSort('potDistribution')}
              className={styles.sortableHeader}
              id="trial-league-award-distribution"
            >
              {t('allLeagues.potDistribution')} {getSortIcon('potDistribution')}
            </th>
            <th
              onClick={() => handleSort('finishDate')}
              className={styles.sortableHeader}
            >
              {t('allLeagues.finishDate')} {getSortIcon('finishDate')}
            </th>
            <th>{t('common.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {getSortedLeagues().map((league, index) => (
            <tr
              key={league._id}
              className={styles.leagueRow}
              id={index === 0 ? 'trial-league' : undefined}
            >
              <td className={styles.nameCell}>
                <div className={styles.nameContainer}>
                  <img
                    className={styles.leagueImage}
                    src={league.photo}
                    alt={league.nameLeague}
                  />
                  <span className={styles.leagueName}>{league.nameLeague}</span>
                </div>
              </td>
              <td>{league.inscriptionPrice}€</td>
              <td>
                {league.participants.length}/{league.maxParticipants}
              </td>
              <td>{league.potToWinners.toFixed(2)}€</td>
              <td>{league.potDistribution || '1st: 100%'}</td>
              <td>{new Date(league.finishDate * 1000).toLocaleDateString()}</td>
              <td
                className={styles.actions}
                id={index === 0 ? 'trial-league-join-league' : undefined}
              >
                {renderLeagueActions(league, userMoney)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const steps = [
    {
      target: 'body',
      content: t('allLeagues.onboarding.welcome'),
      placement: 'center',
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      target: '#trial-league',
      content: t('allLeagues.onboarding.trialLeague'),
      disableBeacon: true,
    },
    {
      target: '#trial-league-inscription-cost',
      content: t('allLeagues.onboarding.inscriptionCost'),
      disableBeacon: true,
    },
    {
      target: '#trial-league-participants',
      content: t('allLeagues.onboarding.participants'),
      disableBeacon: true,
    },
    {
      target: '#trial-league-award',
      content: t('allLeagues.onboarding.award'),
      disableBeacon: true,
    },
    {
      target: '#trial-league-award-distribution',
      content: t('allLeagues.onboarding.awardDistribution'),
      disableBeacon: true,
    },
    {
      target: '#trial-league-join-league',
      content: t('allLeagues.onboarding.joinLeague'),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  const renderLeagueActions = useCallback(
    (league, userMoney) => {
      if (
        league.participants?.some(
          participant => participant.username === userName
        )
      ) {
        return (
          <button
            type="button"
            onClick={() => {
              dispatch(betLeagueId(league._id));
              dispatch(setGameName(league.nameLeague));
              dispatch(setGameId(league._id));
              dispatch(setGameType('leagues'));
              history.push(`/home/leagues/${league._id}/${league.nameLeague}`);
            }}
            className={styles.joinLeagueButton}
          >
            {t('allLeagues.enter')}
          </button>
        );
      }

      if (league.participants.length >= league.maxParticipants) {
        return (
          <p className={styles.redText}>
            <b>{t('allLeagues.leagueFull')}</b>
          </p>
        );
      }

      if (userMoney < league.inscriptionPrice) {
        return (
          <p className={styles.redText}>
            <b>{t('allLeagues.notEnoughMoney')}</b>
          </p>
        );
      }

      const currentAccessCode = accessCodes[league._id] || '';

      if (league.accessCode !== '') {
        return (
          <>
            <input
              type="text"
              className={styles.accessCodeInput}
              name="accessCode"
              value={currentAccessCode}
              onChange={e => handleAccessCodeChange(league._id, e)}
              placeholder={t('allLeagues.accessCode')}
            />
            {currentAccessCode && league.accessCode !== currentAccessCode && (
              <p className={styles.redText}>
                {t('allLeagues.incorrectAccessCode')}
              </p>
            )}
          </>
        );
      }

      return (
        <>
          <button
            type="button"
            onClick={() =>
              history.push(
                `/home/leagues/all-leagues/${league.nameLeague}/${league._id}`
              )
            }
            className={styles.seeLeagueButton}
          >
            {t('allLeagues.seeLeague')}
          </button>
          <button
            type="submit"
            className={styles.joinLeagueButton}
            onClick={() => handleJoinLeague(league, accessCodes[league._id])}
          >
            {t('allLeagues.joinLeague')}
          </button>
        </>
      );
    },
    [
      dispatch,
      handleAccessCodeChange,
      history,
      userName,
      t,
      accessCodes,
      handleJoinLeague,
    ]
  );

  const renderMobileLeague = (league, index) => (
    <div key={league._id} className={styles.mobileCard}>
      <div className={styles.mobileCardHeader}>
        <span className={styles.mobileId}>#{index + 1}</span>
        <div className={styles.nameContainer}>
          <img
            className={styles.leagueImage}
            src={league.photo}
            alt={league.nameLeague}
          />
          <h3 className={styles.mobileName}>{league.nameLeague}</h3>
        </div>
      </div>
      <div className={styles.mobileCardBody}>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allLeagues.inscriptionCost')}
          </span>
          <span className={styles.mobileValue}>{league.inscriptionPrice}€</span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allLeagues.participants')}
          </span>
          <span className={styles.mobileValue}>
            {league.participants.length}/{league.maxParticipants}
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>{t('allLeagues.pot')}</span>
          <span className={styles.mobileValue}>
            {league.potToWinners.toFixed(2)}€
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allLeagues.potDistribution')}
          </span>
          <span className={styles.mobileValue}>
            {league.potDistribution || '1st: 100%'}
          </span>
        </div>
        <div className={styles.mobileRow}>
          <span className={styles.mobileLabel}>
            {t('allLeagues.finishDate')}
          </span>
          <span className={styles.mobileValue}>
            {new Date(league.finishDate * 1000).toLocaleDateString()}
          </span>
        </div>
      </div>
      <div className={styles.mobileActions}>
        {renderLeagueActions(league, userMoney)}
      </div>
    </div>
  );

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <h1 className={styles.title}>{t('allLeagues.title')}</h1>

        <div className={styles.searchAndFilters}>
          <input
            type="text"
            onChange={handleLeagueSearch}
            placeholder={t('allLeagues.searchLabel')}
            className={styles.searchInput}
          />
        </div>

        <div className={styles.desktopView}>{renderDesktopTable()}</div>

        <div className={styles.mobileView}>
          {getSortedLeagues().map((league, index) =>
            renderMobileLeague(league, index)
          )}
        </div>
      </div>

      {isLoggedIn && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          stepIndex={onboardingStep}
          callback={handleJoyrideCallback}
          hideBackButton={true}
          styles={{
            options: {
              primaryColor: '#007bff',
              zIndex: 1000,
            },
            tooltip: {
              fontSize: '14px',
              padding: '15px',
            },
            buttonNext: {
              backgroundColor: '#007bff',
            },
          }}
          floaterProps={{
            disableAnimation: true,
          }}
        />
      )}
    </>
  );
};

export default AllLeagues;
