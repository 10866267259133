import {useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Navigation from '../../components/Navbar';
import {useDispatch, useSelector} from 'react-redux';
import {betLeagueId, betLeagueName} from '../../redux/betSlice';
import {setGameName, setGameId, setGameType} from '../../redux/actualGameSlice';
import {setUserMoney} from '../../redux/userSlice';
import {useTranslation} from 'react-i18next';
import styles from './myLeagues.module.css';
import BreadAndLanguage from '../../components/BreadAndLanguage';

const API_URL = process.env.REACT_APP_API_URL;

const MyLeagues = () => {
  const {t} = useTranslation();
  const {userId} = useSelector(state => state.user);
  const [myLeagues, setMyLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const getMyLeagues = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${API_URL}/my-leagues`, {
        userId,
      });

      // Update leagues state
      setMyLeagues(response.data.openLeagues);

      // Update user's coins in Redux store
      dispatch(setUserMoney(response.data.userCoins));
    } catch (error) {
      console.error('Error fetching leagues:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, dispatch]);

  useEffect(() => {
    getMyLeagues();
  }, [getMyLeagues]);

  const getSortedLeagues = () => {
    if (!sortConfig.key || !myLeagues) return myLeagues;
    return [...myLeagues].sort((a, b) => {
      if (!a || !b) return 0;

      let aValue, bValue;

      switch (sortConfig.key) {
        case 'name':
          aValue = (a.nameLeague || '').toLowerCase();
          bValue = (b.nameLeague || '').toLowerCase();
          break;
        case 'inscriptionPrice':
          aValue = parseFloat(a.inscriptionPrice) || 0;
          bValue = parseFloat(b.inscriptionPrice) || 0;
          break;
        case 'participants':
          aValue = Array.isArray(a.participants) ? a.participants.length : 0;
          bValue = Array.isArray(b.participants) ? b.participants.length : 0;
          break;
        case 'potToWinners':
          aValue = parseFloat(a.potToWinners) || 0;
          bValue = parseFloat(b.potToWinners) || 0;
          break;
        case 'potDistribution':
          aValue = (a.potDistribution || '1st: 100%').toLowerCase();
          bValue = (b.potDistribution || '1st: 100%').toLowerCase();
          break;
        case 'finishDate':
          aValue = a.finishDate ? new Date(a.finishDate * 1000).getTime() : 0;
          bValue = b.finishDate ? new Date(b.finishDate * 1000).getTime() : 0;
          break;
        default:
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
      }

      if (aValue === undefined || aValue === null) aValue = '';
      if (bValue === undefined || bValue === null) bValue = '';

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = useCallback(key => {
    setSortConfig(prevConfig => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending',
    }));
  }, []);

  const getSortIcon = key => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const handleViewLeague = useCallback(
    (leagueId, leagueName) => {
      dispatch(betLeagueId(leagueId));
      dispatch(betLeagueName(leagueName));
      dispatch(setGameName(leagueName));
      dispatch(setGameId(leagueId));
      dispatch(setGameType('leagues'));
      history.push(`/home/leagues/${leagueId}/${leagueName}`);
    },
    [dispatch, history]
  );

  const handleBetLeague = useCallback(
    (leagueId, leagueName) => {
      dispatch(betLeagueId(leagueId));
      dispatch(setGameName(leagueName));
      dispatch(setGameId(leagueId));
      dispatch(setGameType('leagues'));
      history.push(`/home/leagues/${leagueId}/${leagueName}/competitions`);
    },
    [dispatch, history]
  );

  const renderMobileTable = useCallback(
    (league, index) => (
      <div key={`mobile-${league._id}-${index}`} className={styles.mobileCard}>
        <div className={styles.mobileCardHeader}>
          <span className={styles.mobileId}>#{index + 1}</span>
          <h3 className={styles.mobileName}>{league.nameLeague}</h3>
        </div>
        <div className={styles.mobileCardBody}>
          <div className={styles.mobileRow}>
            <span className={styles.mobileLabel}>
              {t('leagueCard.participants')}
            </span>
            <span className={styles.mobileValue}>
              {league.participants.length}/{league.maxParticipants}
            </span>
          </div>
          <div className={styles.mobileRow}>
            <span className={styles.mobileLabel}>
              {t('leagueCard.finishDate')}
            </span>
            <span className={styles.mobileValue}>
              {new Date(league.finishDate * 1000).toLocaleDateString()}
            </span>
          </div>
          <div className={styles.mobileRow}>
            <span className={styles.mobileLabel}>
              {t('leagueCard.coinsToPlay')}
            </span>
            <span className={styles.mobileValue}>
              {league.numberCoinsInLeague}
            </span>
          </div>
          <div className={styles.mobileRow}>
            <span className={styles.mobileLabel}>{t('leagueCard.award')}</span>
            <span className={styles.mobileValue}>{league.potToWinners}€</span>
          </div>
        </div>
        <div className={styles.mobileActions}>
          <button
            onClick={() => handleViewLeague(league._id, league.nameLeague)}
            className={styles.viewButton}
          >
            {t('leagueCard.view')}
          </button>
          <button
            onClick={() => handleBetLeague(league._id, league.nameLeague)}
            className={styles.betButton}
          >
            {t('leagueCard.bet')}
          </button>
        </div>
      </div>
    ),
    [handleBetLeague, handleViewLeague, t]
  );

  const renderDesktopTable = useCallback(
    () => (
      <div className={styles.tableContainer}>
        <table className={styles.leaguesTable}>
          <thead>
            <tr>
              <th
                onClick={() => handleSort('name')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.name')} {getSortIcon('name')}
              </th>
              <th
                onClick={() => handleSort('inscriptionPrice')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.inscriptionCost')}{' '}
                {getSortIcon('inscriptionPrice')}
              </th>
              <th
                onClick={() => handleSort('participants')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.participants')} {getSortIcon('participants')}
              </th>
              <th
                onClick={() => handleSort('potToWinners')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.pot')} {getSortIcon('potToWinners')}
              </th>
              <th
                onClick={() => handleSort('potDistribution')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.potDistribution')}{' '}
                {getSortIcon('potDistribution')}
              </th>
              <th
                onClick={() => handleSort('finishDate')}
                className={styles.sortableHeader}
              >
                {t('allLeagues.finishDate')} {getSortIcon('finishDate')}
              </th>
              <th>{t('common.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {getSortedLeagues().map((league, index) => (
              <tr
                key={`desktop-${league._id}-${index}`}
                className={styles.leagueRow}
              >
                <td className={styles.nameCell}>
                  <div className={styles.nameContainer}>
                    <img
                      className={styles.leagueImage}
                      src={league.photo}
                      alt={league.nameLeague}
                    />
                    <span className={styles.leagueName}>
                      {league.nameLeague}
                    </span>
                  </div>
                </td>
                <td>{league.inscriptionPrice}€</td>
                <td>
                  {league.participants.length}/{league.maxParticipants}
                </td>
                <td>{league.potToWinners.toFixed(2)}€</td>
                <td>{league.potDistribution || '1st: 100%'}</td>
                <td>
                  {new Date(league.finishDate * 1000).toLocaleDateString()}
                </td>
                <td className={styles.actions}>
                  <button
                    onClick={() =>
                      handleViewLeague(league._id, league.nameLeague)
                    }
                    className={styles.viewButton}
                  >
                    {t('leagueCard.view')}
                  </button>
                  <button
                    onClick={() =>
                      handleBetLeague(league._id, league.nameLeague)
                    }
                    className={styles.betButton}
                  >
                    {t('leagueCard.bet')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    [getSortedLeagues, handleBetLeague, handleSort, handleViewLeague, t]
  );

  if (isLoading) {
    return (
      <>
        <Navigation />
        <div className={styles.loading}>
          <div className={styles.loadingSpinner}></div>
          <p>{t('common.loading')}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <div className={styles.container}>
        <h1 className={styles.title}>{t('myLeagues.title')}</h1>
        {myLeagues.length === 0 ? (
          <div className={styles.emptyState}>
            <p>{t('myLeagues.noLeagues')}</p>
            <button onClick={() => history.push('/home/leagues/all-leagues')}>
              {t('myLeagues.seeLeagues')}
            </button>
          </div>
        ) : (
          <>
            <div className={styles.desktopView}>{renderDesktopTable()}</div>
            <div className={styles.mobileView}>
              {getSortedLeagues().map((league, index) =>
                renderMobileTable(league, index)
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyLeagues;
