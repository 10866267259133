import Breadcrumbs from './Breadcrumbs';
import LanguageSwitcher from './LanguageSwitcher';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './BreadAndLanguage.module.css';

const BreadAndLanguage = () => {
  const {userMoney} = useSelector(state => state.user);
  const history = useHistory();
  const {t} = useTranslation();

  const handleDepositClick = () => {
    history.push('/home/deposit');
  };

  return (
    <div className={styles.BreadAndLanguageWrapper}>
      <div className={styles.leftSection}>
        <Breadcrumbs />
      </div>
      <div className={styles.rightSection}>
        <div className={styles.moneyContainer} onClick={handleDepositClick}>
          <div className={styles.moneyAmount}>
            <span className={styles.moneyValue}>{userMoney.toFixed(2)}€</span>
            <div className={styles.depositWrapper}>
              <span className={styles.depositIcon}>+</span>
              <span className={styles.depositText}>
                {t('breadcrumbs.deposit')}
              </span>
            </div>
          </div>
        </div>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default BreadAndLanguage;
