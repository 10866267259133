// MatchesCompetitionsBet.js
import {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import {useParams, useHistory} from 'react-router-dom';
import {AuthContext} from '../../context/auth.context';
import Navigation from '../../components/Navbar';
import SubNavbar from '../../components/SubNavbar';
import MatchList from '../../components/MatchList';

// ELIMINAMOS LAS IMPORTACIONES ANTIGUAS
// import MatchDetails from '../../components/MatchDetails';
// import MatchDetailsBasket from '../../components/MatchDetailsBasket';
// import MatchDetailsTennis from '../../components/MatchDetailsTennis';

import BreadAndLanguage from '../../components/BreadAndLanguage';
import Modal from '../../components/Modal';
import ModalBet from '../../components/ModalBet';
import {useTranslation} from 'react-i18next';
import Joyride, {STATUS} from 'react-joyride';
import {useSelector, useDispatch} from 'react-redux';
import {incrementOnboardingStep, resetOnboarding} from '../../redux/userSlice';
import styles from './MatchesCompetitionsBet.module.css';

// IMPORTAMOS EL COMPONENTE UNIFICADO
import MatchDetailsUnified from '../../components/MatchDetailsUnified';

const API_URL = process.env.REACT_APP_API_URL;

const MatchesCompetitionsBet = () => {
  const {t} = useTranslation();
  const {
    id: leagueId,
    compId: competitionId,
    sport: sportId,
    game,
  } = useParams();
  const {isLoggedIn, user} = useContext(AuthContext);
  const dispatch = useDispatch();

  // States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userLeague, setUserLeague] = useState('');
  const [infoDouble, setInfoDouble] = useState('');
  const [leagueInfo, setLeagueInfo] = useState();
  const [matchDetails, setMatchDetails] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Onboarding flags
  const isNewUser = useSelector(state => state.user.isNewUser);
  const onboardingStep = useSelector(state => state.user.onboardingStep);
  const [runTour, setRunTour] = useState(false);
  const [showMatchDetails, setShowMatchDetails] = useState(false);

  const history = useHistory();

  // Detecta resize para el modo mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch datos de la liga y partidos
  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        try {
          const competitionData = {competitionId, sportId};
          const [
            leagueResponse,
            matchesResponse,
            userLeagueResponse,
            doubleFinishResponse,
          ] = await Promise.all([
            axios.get(`${API_URL}/double-details/${leagueId}`),
            axios.post(`${API_URL}/leagues-matches`, competitionData),
            axios.post(`${API_URL}/get-userleague`, {
              leagueId,
              userId: user._id,
            }),
            axios.post(`${API_URL}/get-alluserindouble`, {doubleId: leagueId}),
          ]);

          setLeagueInfo(leagueResponse.data[0]);
          setMatches(matchesResponse.data);
          setUserLeague(userLeagueResponse.data[0]);
          setInfoDouble(doubleFinishResponse.data[0]);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setError(t('matchesCompetitionsBet.error'));
          setLoading(false);
        }
      }
    };

    fetchData();

    // Si es usuario nuevo, iniciamos el tutorial
    if (isLoggedIn && isNewUser) {
      setRunTour(true);
    }
  }, [isLoggedIn, isNewUser, t, competitionId, sportId, leagueId, user?._id]);

  // Pasos de Joyride para la lista de partidos (no para los detalles)
  const steps = [
    {
      target: '#match-card',
      content: t('matchesCompetitionsBet.onboarding.matchIntro'),
      disableBeacon: true,
      disableOverlay: true,
    },
    {
      target: '#match-teams-container',
      content: t('matchesCompetitionsBet.onboarding.teamNames'),
      disableBeacon: true,
    },
    {
      target: '#match-date',
      content: t('matchesCompetitionsBet.onboarding.finishDate'),
      disableBeacon: true,
    },
    {
      target: '#match-odds-button',
      content: t('matchesCompetitionsBet.onboarding.seeOddsButton'),
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = data => {
    const {status, type} = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(resetOnboarding());
      setRunTour(false);
    } else if (type === 'step:after') {
      dispatch(incrementOnboardingStep());
    }
  };

  // Mostrar detalles de un partido específico
  const showDetails = async (id, sportId) => {
    try {
      const matchResponse = await axios.post(`${API_URL}/show-odds`, {
        id,
        sportId,
      });
      setMatchDetails({...matchResponse.data, id});
      setShowMatchDetails(true);
    } catch (error) {
      console.error('Error fetching match details:', error);
      setError(t('matchesCompetitionsBet.error'));
    }
  };

  if (loading) {
    return <div>{t('matchesCompetitionsBet.loading')}</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (matches.length === 0) {
    return (
      <>
        <Navigation />
        <BreadAndLanguage />
        <SubNavbar
          id={leagueId}
          game={game}
          leagueName={leagueInfo?.nameLeague}
        />
        <div className={styles.noMatchesContainer}>
          <p>{t('matchesCompetitionsBet.noMatches')}</p>
          <button
            className={styles.backButton}
            onClick={() => history.goBack()}
          >
            {t('common.goBack')}
          </button>
        </div>
      </>
    );
  }

  // Función auxiliar para mapear el sportId al sportType requerido por el unificado
  const getSportType = sport => {
    if (sport === '1') return 'soccer';
    if (sport === '18') return 'basket';
    if (sport === '13') return 'tennis';
    return 'soccer'; // Por defecto
  };

  return (
    <>
      <Navigation />
      <BreadAndLanguage />
      <SubNavbar
        id={leagueId}
        game={game}
        leagueName={leagueInfo?.nameLeague}
      />

      {isLoggedIn && (
        <Joyride
          steps={steps}
          run={runTour}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          stepIndex={onboardingStep}
          callback={handleJoyrideCallback}
          hideBackButton={true}
          styles={{
            options: {
              primaryColor: '#007bff',
              zIndex: 1000,
            },
            tooltip: {
              fontSize: '14px',
              padding: '15px',
            },
            buttonNext: {
              backgroundColor: '#007bff',
            },
          }}
          floaterProps={{
            disableAnimation: true,
          }}
        />
      )}

      <div className={styles.matchesContainer}>
        {/* Listado de Partidos */}
        <div
          className={`${styles.matchList} ${
            showMatchDetails && isMobile ? styles.hidden : ''
          }`}
        >
          <MatchList
            matches={matches}
            userLeague={userLeague}
            infoDouble={infoDouble}
            loading={loading}
            showDetails={showDetails}
            sportId={sportId}
            isModalOpen={isModalOpen}
            isMobile={isMobile}
          />
        </div>

        {/* Detalles de Partidos (versión unificada) */}
        <div
          className={`${styles.matchDetails} ${
            !showMatchDetails && isMobile ? styles.hidden : ''
          }`}
        >
          {/* Usamos el componente unificado directamente */}
          {matchDetails && (
            <MatchDetailsUnified
              sportType={getSportType(sportId)} // mapeo: '1'->soccer, '18'->basket, '13'->tennis
              matchDetails={matchDetails}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              showMatchDetails={showMatchDetails}
              setShowMatchDetails={setShowMatchDetails}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>

      {/* Modal de apuesta */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => !isNewUser && setIsModalOpen(false)}
      >
        <ModalBet
          isOpen={isModalOpen}
          isNewUser={isNewUser}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </>
  );
};

export default MatchesCompetitionsBet;
