import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {formatDecimal} from '../helpers/formatDecimal';
import PropTypes from 'prop-types';
import styles from './leagueClassification.module.css';
import {useTranslation} from 'react-i18next';

const LeagueClassification = ({
  usersCoinsInfo,
  leagueId,
  potDistribution,
  seeLeague,
}) => {
  const {t} = useTranslation();
  const {gameName} = useSelector(state => state.actualGame);

  const sortedUsers = useMemo(() => {
    return [...usersCoinsInfo].sort(
      (a, b) =>
        b.coinsInLeague + b.inPlayCoins - (a.coinsInLeague + a.inPlayCoins)
    );
  }, [usersCoinsInfo]);

  const getRowClassName = (index, totalUsers) => {
    const currentUser = sortedUsers[index];
    const totalCoins = currentUser.coinsInLeague + currentUser.inPlayCoins;

    const isEqualToFirst =
      totalCoins === sortedUsers[0].coinsInLeague + sortedUsers[0].inPlayCoins;

    switch (potDistribution) {
      case 'allWinners':
        return isEqualToFirst ? `${styles.firstPlace} firstPlace` : '';
      case 'first-second-third':
        if (isEqualToFirst) return `${styles.firstPlace} firstPlace`;
        if (
          index <= 1 ||
          totalCoins ===
            sortedUsers[1].coinsInLeague + sortedUsers[1].inPlayCoins
        )
          return `${styles.secondPlace} secondPlace`;
        if (
          index <= 2 ||
          totalCoins ===
            sortedUsers[2].coinsInLeague + sortedUsers[2].inPlayCoins
        )
          return `${styles.thirdPlace} thirdPlace`;
        return '';
      case 'half': {
        const halfIndex = Math.ceil(totalUsers / 2) - 1;
        const lastWinnerCoins =
          sortedUsers[halfIndex].coinsInLeague +
          sortedUsers[halfIndex].inPlayCoins;
        if (index <= halfIndex || totalCoins === lastWinnerCoins) {
          return `${styles.winner} winner`;
        }
        return '';
      }
      default:
        return '';
    }
  };

  return (
    <div className="col-12 clasificationLeague">
      <h2>{t('leagueClassification.classification')}</h2>
      <table className={`${styles.classificationTable} classificationTable`}>
        <thead>
          <tr>
            <th scope="col">{t('leagueClassification.users')}</th>
            <th scope="col">{t('leagueClassification.coins')}</th>
            <th scope="col">{t('leagueClassification.inplayCoins')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user, index) => (
            <tr
              key={`${user.userId._id}-${index}`}
              className={getRowClassName(index, sortedUsers.length)}
            >
              <td>
                {seeLeague ? (
                  user.userId.username
                ) : (
                  <Link
                    to={`/home/leagues/${gameName}/${leagueId}/${user.userId._id}/${user.userId.username}`}
                  >
                    {user.userId.username}
                  </Link>
                )}
              </td>
              <td>{formatDecimal(user.coinsInLeague)}</td>
              <td>{formatDecimal(user.inPlayCoins)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

LeagueClassification.propTypes = {
  usersCoinsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.shape({
        _id: PropTypes.string,
        username: PropTypes.string,
      }),
      coinsInLeague: PropTypes.number,
      inPlayCoins: PropTypes.number,
    })
  ).isRequired,
  leagueId: PropTypes.string.isRequired,
  potDistribution: PropTypes.string,
  seeLeague: PropTypes.bool,
};

export default LeagueClassification;
